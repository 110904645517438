import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.token$.pipe(
    map((token) => !!token || router.parseUrl(route.data['redirectTo']))
  );
};

export const combinedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.token$.pipe(
    switchMap((token) => {
      if (token) {
        return of(true);
      } else {
        return authService.accessCode$.pipe(
          map(
            (accessCode) =>
              !!accessCode || router.parseUrl(route.data['redirectTo'])
          )
        );
      }
    })
  );
};
