import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  NgxPermissionsService,
  NgxRolesService,
  ValidationFn,
} from 'ngx-permissions';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  forkJoin,
  from,
  of,
} from 'rxjs';
import { decodeToken, isTokenExpired } from '../util/jwtToken';

import {
  catchError,
  concatMap,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  share,
  take,
  tap,
} from 'rxjs/operators';
import { AuthConfig } from '../interface';
import { APP_ADMIN_PERMISSIONS, APP_PERMISSIONS, OAUTH_CONFIG } from '../token';

import { API_BASE } from '@core/api';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticated = new BehaviorSubject<boolean>(false);

  private validateToken = new Subject<void>();
  private validateAccessCode = new Subject<void>();
  private validToken$: Observable<string | null> = this.validateToken.pipe(
    map(() => [
      this.storage.getItem('access_token'),
      this.storage.getItem('refresh_token'),
    ]),
    concatMap(([access_token, refresh_token]) => {
      if (access_token !== null && !isTokenExpired(access_token)) {
        return of(access_token);
      } else if (refresh_token !== null && this.http) {
        return this.http
          .post(`${this.apiBase}/oauth/token`, {
            grant_type: 'refresh_token',
            refresh_token,
            client_id: this.oauth.client_id,
            client_secret: this.oauth.client_secret,
            scope: '',
          })
          .pipe(
            map((data: any) => {
              this.storage.setItem('access_token', data.access_token);
              this.storage.setItem('refresh_token', data.refresh_token);
              return data.access_token;
            }),
            catchError((e) => {
              this.storage.clear();
              this.isAuthenticated.next(false);
              return of('session-expired');
            })
          );
      } else {
        this.isAuthenticated.next(false);
        return of(null);
      }
    }),
    share()
  );

  private validAccessCode$ = this.validateAccessCode.pipe(
    map(() => [this.storage.getItem('access_code')]),
    concatMap(([access_code]) => {
      if (access_code !== null) {
        return of(access_code);
      } else {
        return of(null);
      }
    }),
    share()
  );

  get token$(): Observable<string | null> {
    return new Observable((subscriber) => {
      this.validToken$.pipe(take(1)).subscribe((x) => {
        subscriber.next(x);
        subscriber.complete();
      });
      this.validateToken.next();
    });
  }

  get accessCode$(): Observable<string | null> {
    return new Observable((subscriber) => {
      this.validAccessCode$.pipe(take(1)).subscribe((x) => {
        subscriber.next(x);
        subscriber.complete();
      });
      this.validateAccessCode.next();
    });
  }

  get isAuthenticated$() {
    return this.isAuthenticated.pipe(distinctUntilChanged());
  }

  get userId(): number | null {
    const userId = this.storage.getItem('user_id');
    return userId !== null && !isNaN(+userId) ? +userId : null;
  }

  constructor(
    @Inject(WA_LOCAL_STORAGE) readonly storage: Storage,
    @Inject(API_BASE) readonly apiBase: string,
    @Inject(OAUTH_CONFIG) readonly oauth: AuthConfig,
    @Optional() @Inject(APP_PERMISSIONS) readonly permissions: string[],
    @Optional()
    @Inject(APP_ADMIN_PERMISSIONS)
    readonly adminPermissions: string[],
    private http: HttpClient,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) {
    if (permissions instanceof Array) {
      this.permissionsService.loadPermissions(
        permissions,
        this.checkPermission as ValidationFn
      );
    }
    // this.rolesService.addRoles(roles);
    // this.isAuthenticated.next(false);

    this.token$.subscribe((token) => this.isAuthenticated.next(!!token));
    this.accessCode$.subscribe((accessCode) => {
      if (accessCode) {
        this.isAuthenticated.next(!!accessCode);
      }
    });
  }

  /**
   * Get an access token
   * @param username The email address
   * @param password The password string
   */
  login(username: string, password: string, redirect?: (string | number)[]) {
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        roles: string[];
        user_id: number;
        customer: unknown | null;
      }>(`${this.apiBase}/oauth/token`, {
        grant_type: 'password',
        client_id: this.oauth.client_id,
        client_secret: this.oauth.client_secret,
        username,
        password,
        scope: '',
      })
      .pipe(
        tap((data) => {
          const decodedToken = decodeToken(data.access_token);
          this.storage.setItem('access_token', data.access_token);
          this.storage.setItem('refresh_token', data.refresh_token);
          if (decodedToken.user_id) {
            this.storage.setItem('user_id', decodedToken.user_id);
          }
          this.storage.setItem('customer', JSON.stringify(data.customer));
          this.isAuthenticated.next(true);
        })
      );
  }

  /**
   * Revoke the authenticated user token
   */
  public logout() {
    return this.http.get(`${this.apiBase}/user/logout`).pipe(
      finalize(() => {
        this.storage.clear();
        this.isAuthenticated.next(false);
      })
    );
  }

  public logoutApp() {
    return this.http.get(`${this.apiBase}/user/logout`).pipe(
      finalize(() => {
        if (this.storage.getItem('access_token') !== null) {
          this.storage.removeItem('access_token');
        }
        if (this.storage.getItem('refresh_token') !== null) {
          this.storage.removeItem('refresh_token');
        }
        if (this.storage.getItem('permissions') !== null) {
          this.storage.removeItem('permissions');
        }
        if (this.storage.getItem('user_id') !== null) {
          this.storage.removeItem('user_id');
        }
        if (this.storage.getItem('customer') !== null) {
          this.storage.removeItem('customer');
        }
        if (this.storage.getItem('access_code') !== null) {
          this.storage.removeItem('access_code');
        }
        this.isAuthenticated.next(false);
      })
    );
  }

  public forgotPassword(email: string) {
    return this.http.post<{
      message: string;
      status: number;
      data: null | unknown[];
    }>(`${this.apiBase}/api/component/users/forgotPassword`, { email });
  }

  public resetPassword(
    hash: string,
    password: string,
    passwordConfirmation: string
  ) {
    return this.http.post<{
      message: string;
      status: number;
      data: null | unknown[];
    }>(`${this.apiBase}/api/component/users/resetPassword`, {
      hash,
      pw1: password,
      pw2: passwordConfirmation,
    });
  }

  public changePassword(
    oldPassword: string,
    password: string,
    passwordConfirmation: string
  ) {
    return this.http.post<{
      message: string;
      status: number;
      data: null | unknown[];
    }>(`${this.apiBase}/api/component/users/changePassword`, {
      oldPassword,
      pw1: password,
      pw2: passwordConfirmation,
    });
  }

  public hasPermission = (...permissions: string[]) => {
    const userPermissions = JSON.parse(
      this.storage.getItem('permissions') || '[]'
    );
    return userPermissions instanceof Array
      ? permissions.every((permission) => userPermissions.includes(permission))
      : false;
  };

  public checkPermission = (...permissions: string[]) => {
    const userPermissions = JSON.parse(
      this.storage.getItem('permissions') || '[]'
    );

    return userPermissions instanceof Array
      ? permissions.some(
          (permission) =>
            userPermissions.some((userPermission) =>
              this.adminPermissions.includes(userPermission)
            ) || userPermissions.includes(permission)
        )
      : false;
  };

  public loginWithAccesCode(code: string) {
    this.storage.setItem('access_code', code);
    this.storage.setItem('permissions', '');
    this.storage.setItem('user_id', '');
    this.storage.setItem('customer', '');

    this.isAuthenticated.next(true);
    return of(code);
  }
}
